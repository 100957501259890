import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, []);

  return (
    <div className="App">
      {/* <h1 className="text-3xl font-bold underline">
                This is the home page
            </h1> */}
      <Link to="/login">{/* Login */}</Link>
    </div>
  );
};

export default HomePage;
