export default function DropDownSelect({ options, onSelect }) {
  return (
    <div>
      <label htmlFor="type" className="block text-sm font-medium text-gray-700">
        Select form type
      </label>
      <select
        onChange={(e) => onSelect(e.target.value)}
        id="type"
        name="type"
        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      >
        <option key="default" selected="selected"></option>
        {options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </select>
    </div>
  )
}
