import mainInfo from './common/mainInfo.js'
import keyGoals from './common/keyGoals.js'
import keySkills from './common/keySkills.js'


const individualContributor = {
  "id": "individual-contributor",
  "config": {
    "title": "Individual Contributor Appraisal Form",
    "description": "All Functions And Teams",
    "logoPosition": "right",
    "showQuestionNumbers": "off",
    'showPreviewBeforeComplete': "showAnsweredQuestions",
    "pages": [
      mainInfo,
      {
        "name": "Key Work Delivered",
        "elements": [
          {
            "type": "matrixdynamic",
            "allowAddRows": false,
            "allowRemoveRows": false,
            "name": "Project Overview",
            "title": "Project Overview",
            "columnMinWidth": "200px",
            "verticalAlign": "top",
            "alternateRows": true,
            "description": "Use this space to tell us about projects/goals you have participated in 2022 (Pick your top 5 projects/goals, where you had the largest role or spent the maximum time).  Describe your role and the percentage of time you spent on it.  When describing your achievements, these could be specific outcomes you delivered, any cost savings you delivered, any special circumstances/challenges in the context of which you delivered.  Finally, conclude with the self ratings where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "columns": [
              {
                "name": "Project Name",
                "title": "Project Name",
                "isRequired": true
              },
              {
                "name": "Goal/Activity",
                "title": "Goal/Activity",
                "isRequired": true
              },
              {
                "name": "Your role",
                "title": "Your role",
                "isRequired": true
              },
              {
                "name": "Percentage of your time spent on the goal/client/activity",
                "title": "Percentage of your time spent on the goal/client/activity",
                "isRequired": true
              },
              {
                "name": "Your achievement",
                "title": "Your achievement",
                "cellType": "comment",
                "isRequired": true
              },
              {
                "name": "Self Rating",
                "title": "Self Rating",
                "cellType": "rating",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "text",
            "rowCount": 5
          }
        ],
        "title": "A. Key Work Delivered"
      },
      {
        "name": "Key Skills",
        "elements": [
          {
            "type": "matrixdynamic",
            "allowAddRows": false,
            "allowRemoveRows": false,
            "name": "Picked up skills",
            "title": "Picked up skills",
            "description": "What skills have you picked up in 2022 (These could be any skills, formal or informal, technical or non technical). Explain how you pick it up (formal course/exposure/training by TalentS/observations and experience in the projects, … etc.), and explain how you see yourself using this skill in the future.",
            "isRequired": true,
            "rowCount": 3,
            "columns": [
              {
                "name": "Skill",
                "title": "Skill",
                "cellType": "text",
                "isRequired": true
              },
              {
                "name": "How did you pick this up",
                "title": "How did you pick this up",
                "cellType": "text",
                "isRequired": true
              },
              {
                "name": "How will you use this skill in the future?",
                "title": "How will you use this skill in the future?",
                "cellType": "comment",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
          },
          {
            "type": "comment",
            "name": "What strengths did you demonstrate across your work in 2022?",
            "title": "What strengths did you demonstrate across your work in 2022? List top 3-5 strengths.",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What improvement areas do you identify for yourself for 2023 taking into consideration your contribution in 2022",
            "title": "What improvement areas do you identify for yourself for 2023 taking into consideration your contribution in 2022",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What is your aspiration? What kind of a role and projects, do you want to do with TalentS in 2023?",
            "title": "What is your aspiration? What kind of a role and projects, do you want to do with TalentS in 2023?",
            "isRequired": true
          }
        ],
        "title": "B. Key Skills"
      },
      {
        "name": "page4",
        "elements": [
          {
            "type": "matrixdropdown",
            "name": "Success behaviours",
            "title": "Success behaviours",
            "description": "Please RATE the individuals on the following important success behaviors.  As a guideline for self ratings scale, where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "isRequired": true,
            "columns": [
              {
                "name": "Self Rating",
                "title": "Self Rating"
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "rating",
            "rows": [
              {
                "value": "Attention to detail",
                "text": "Attention to detail - Can spot and fix errors and inconsistencies in information, pays attention to minute aspects of tasks to deliver quality"
              },
              {
                "value": "Accountability",
                "text": "Accountability -  Takes complete ownership of tasks, needs minimal or no follow up"
              },
              {
                "value": "Team work",
                "text": "Team work - Works well with other team members (own or other teams), actively shares information with them, does not make them follow up and chase, goes out of the way to help others"
              },
              {
                "value": "Prudence",
                "text": "Prudence - Follows all laid down processes, ways of working and policies"
              },
              {
                "value": "Initiative",
                "text": "Initiative - Takes initiative outside the boundaries of the role to contribute to the success of the function."
              },
              {
                "value": "Achievement orientation",
                "text": "Achievement orientation - Has fire in the belly, wants to succeed and makes deliberate effort to do well and outdo oneself"
              },
              {
                "value": "Resourcefulness",
                "text": "Resourcefulness - Thinks a few steps ahead, anticipates obstacles and takes action to mitigate them, makes things happen."
              },
              {
                "value": "Adaptability and flexibility",
                "text": "Adaptability and flexibility - Is adaptable to changing scope and emerging customer expectations. Is flexible to accommodate new ways of working, make adjustments for accommodating different timelines, working hours etc."
              },
              {
                "value": "Discipline",
                "text": "Discipline - Maintains work discipline and timings, respects deadlines, complies with organizational ethics, does not have attendance and other discipline issues."
              }
            ]
          }
        ],
        "title": "C. Key Success Behaviours"
      }
    ]
  }
}


export default individualContributor;