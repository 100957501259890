const surveysEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
// const surveysEndpoint = "http://127.0.0.1:8000";
const token = localStorage.getItem("userToken");

const surveyService = {
  getSurveys: async (
    skip: number,
    token: string | null,
    filter?: string | null
  ) => {
    const response = await fetch(
      // `${surveysEndpoint}/surveys/`,
      `${surveysEndpoint}/surveys/?skip=${skip}&filter=${filter}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log("data", data);
    if (response.status !== 200) {
      console.error(data, "error");
      throw new Error(data);
    }
    return data;
  },
  getAllSurveys: async () => {
    const response = await fetch(`${surveysEndpoint}/surveys/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log(data);
    if (response.status !== 200) {
      console.error(data);
      throw new Error(data);
    }
    return {
      profiles: data.profiles,
      count: data.count,
      limit: data.limit,
    };
  },
  getSingleSurvey: async (id: string, token: string | null) => {
    const response = await fetch(`${surveysEndpoint}/surveys/details/${id}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log(data);
    if (response.status !== 200) {
      console.error(data);
      throw new Error(data);
    }
    return data;
  },
};

export default surveyService;
