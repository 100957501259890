import mainInfo from './common/mainInfo.js'
import keyGoals from './common/keyGoals.js'
import keySkills from './common/keySkills.js'


const bdManager = {
  "id": "bd-manager",
  "config": {
    "title": "BD MANAGER APPRAISAL FORM",
    "logoPosition": "right",
    "showQuestionNumbers": "off",
    'showPreviewBeforeComplete': "showAnsweredQuestions",
    "pages": [
      mainInfo,
      {
        "name": "Business Line Projects Overview",
        "elements": [
          {
            "type": "matrixdropdown",
            "name": "Business Line Projects Overview Part 1",
            "title": "Business Line Projects Overview Part 1",
            "description": "For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "columns": [
              {
                "name": "2022",
                "title": "in 2022",
                "isRequired": true
              },
              {
                "name": "2021",
                "title": "in 2021",
                "isRequired": true
              },
              {
                "name": "Self Rating",
                "title": "Self Rating",
                "cellType": "rating",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "text",
            "rows": [
              "Financial value of the projects contracted",
              "Number of projects contracted",
              "Average value of contracted projects",
              "Number of projects contracted with existing client",
              "Number of projects from new clients",]
          },
          {
            "type": "matrixdropdown",
            "name": "Business Line Projects Overview Part 2",
            "title": "Business Line Projects Overview Part 2",
            "description": "For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "columns": [
              {
                "name": "2022",
                "title": "in 2022",
                "isRequired": true
              },
              {
                "name": "2021",
                "title": "in 2021",
                "isRequired": true
              },
              {
                "name": "Reason",
                "title": "Reason",
                "isRequired": true
              },
              {
                "name": "Self Rating",
                "title": "Self Rating",
                "cellType": "rating",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "text",
            "rows": [
              "Number of lost projects",
              "Number of contracted projects that had major changes in scope",
              "Number of contracted projects that were cancelled",
            ]
          }
        ],
        "title": "A. Business Line Projects Overview"
      },
      keyGoals,
      {
        "name": "Key Skills",
        "elements": [
          {
            "type": "matrixdynamic",
            "allowAddRows": false,
            "allowRemoveRows": false,
            "rowCount": 3,
            "name": "Picked up skills",
            "title": "Picked up skills",
            "description": "What skills have you picked up in 2022 (These could be any skills, formal or informal, technical or non technical). Explain How did you pick this up (formal course/exposure/training by TalentS/observation and experience in the project etc.)",
            "isRequired": true,
            "columns": [
              {
                "name": "Skill",
                "title": "Skill",
                "cellType": "text",
                "isRequired": true
              },
              {
                "name": "How did you pick this up",
                "title": "How did you pick this up",
                "cellType": "text",
                "isRequired": true
              },
              {
                "name": "How will you use this skill in the future?",
                "title": "How will you use this skill in the future?",
                "cellType": "text",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
          },
          {
            "type": "comment",
            "name": "Use this space to share specific cost saving and optimization initiatives you have taken",
            "title": "Use this space to share specific cost saving and optimization initiatives you have taken",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "Use this space to share 2 examples of innovation you have demonstrated in any of the projects that you have undertaken in 2022",
            "title": "Use this space to share 2 examples of innovation you have demonstrated in any of the projects that you have undertaken in 2022",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "Use this space to share 2 most important people management initiative you have taken in your team",
            "title": "Use this space to share 2 most important people management initiative you have taken in your team",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What strengths did you demonstrate across your work in 2022? List top 3-5 strengths.",
            "title": "What strengths did you demonstrate across your work in 2022? List top 3-5 strengths.",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What improvement areas do you identify for yourself for 2023 taking into consideration your contribution in 2022",
            "title": "What improvement areas do you identify for yourself for 2023 taking into consideration your contribution in 2022",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What is your aspiration? What kind of a role and projects, do you want to do with TalentS in 2023?",
            "title": "What is your aspiration? What kind of a role and projects, do you want to do with TalentS in 2023?",
            "isRequired": true
          }
        ],
        "title": "C. Key Skills"
      },
      {
        "name": "page4",
        "elements": [
          {
            "type": "matrixdropdown",
            "name": "Project Management Competency",
            "title": "Project Management Competency",
            "description": "Please RATE yourself on the following important success behavior. For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "isRequired": true,
            "columns": [
              {
                "name": "Self Rating",
                "title": "Self Rating",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "rating",
            "rows": [
              "Project Conceptualization - Conceptualizes the project, its design elements, its fundamental framework, its mediums of experience, its fitment to Business Line and areas that may need secondary service/support from other Business Lines",
              "Financial Management - Understanding project costs, taking action to optimize costs, balancing cost and quality"
            ]
          },
          {
            "type": "matrixdropdown",
            "name": "Personnel Competency",
            "title": "Personnel Competency",
            "description": "Please RATE yourself on the following important success behavior. For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "isRequired": true,
            "columns": [
              {
                "name": "Self Rating",
                "title": "Self Rating",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "rating",
            "rows": [
              "Innovation mindset - brings new ideas and perspective to all aspects on own work",
              "Accountability - Takes complete ownership of tasks, even in situation where there are joint goals with other teams",
              "Collaboration - Co-creates goals and deliverables with other functions/teams. Manages and mitigates any conflicts",
              "Planning and execution - Plans extensively for projects to make execution predictable. Identifies risk and builds contingencies",
              "Customer Focus - Keeps customer in the centre of all discussions/alignments/decisions, seeks feedback and makes customer services a priority",
              "Achievement orientated - Has fire in the belly, wants to succeed and makes deliberate effort to do well and outdo oneself",
              "Problem solving - Has a solution mindset, generates and evaluates multiple solutions to select the one that is the most apt",
              "Communication excellence - Able to convey information to people clearly and simply, in a way that means things are understood and get done.",
              "Influence - Can influence decisions and mindset of customers/senior leaders - by building compelling arguments",
              "Process orientation  - Creates and drives processes to ensure there is consistency in input and output"
            ]
          }
        ],
        "title": "D. Key Success Behaviours"
      }
    ]
  }
}


export default bdManager;