import mainInfo from './common/mainInfo.js'
import keyGoals from './common/keyGoals.js'
import keySkills from './common/keySkills.js'
import businessLineOverview from './common/businessLineOverview.js'

const bdFunctionHead = {
  "id": "bd-function-head",
  config: {
    "title": "BD Function Head APPRAISAL FORM",
    "logoPosition": "right",
    "showQuestionNumbers": "off",
    'showPreviewBeforeComplete': "showAnsweredQuestions",
    "pages": [
      mainInfo,
      businessLineOverview,
      keyGoals,
      {
        "name": "Key Skills",
        "elements": [
          {
            "type": "matrixdynamic",
            "allowAddRows": false,
            "allowRemoveRows": false,
            "rowCount": 3,
            "name": "Picked up skills",
            "title": "Picked up skills",
            "description": "What skills have you picked up in 2022 (These could be any skills, formal or informal, technical or non technical). Explain How did you pick this up (formal course/exposure/training by TalentS/observation and experience in the project etc.)",
            "isRequired": true,
            "columns": [
              {
                "name": "Skill",
                "title": "Skill",
                "cellType": "text",
                "isRequired": true
              },
              {
                "name": "How did you pick this up",
                "title": "How did you pick this up",
                "cellType": "text",
                "isRequired": true
              },
              {
                "name": "How will you use this skill in the future?",
                "title": "How will you use this skill in the future?",
                "cellType": "text",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
          },
          {
            "type": "comment",
            "name": "What contribution have you made towards ensuring the projects have the higher accuracy in building tendering assumptions?",
            "title": "What contribution have you made towards ensuring the projects have the higher accuracy in building tendering assumptions?",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What areas of your business/function have you transformed via technology or innovation?",
            "title": "What areas of your business/function have you transformed via technology or innovation?",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What new capabilities or skills have you built in your function or business in 2022?",
            "title": "What new capabilities or skills have you built in your function or business in 2022?",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What contribution have you made to hiring/retention/engagement of your direct team",
            "title": "What contribution have you made to hiring/retention/engagement of your direct team",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "topLineContributionContribution",
            "title": "What contribution have you made to increasing the top line growth/revenue/profit of the business?",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What strengths did you demonstrate across your work in 2022? List top 3-5 strengths.",
            "title": "What strengths did you demonstrate across your work in 2022? List top 3-5 strengths.",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What improvement areas do you identify for yourself for 2023 taking into consideration your contribution in 2022",
            "title": "What improvement areas do you identify for yourself for 2023 taking into consideration your contribution in 2022",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What is your aspiration? What kind of a role and projects, do you want to do with TalentS in 2023?",
            "title": "What is your aspiration? What kind of a role and projects, do you want to do with TalentS in 2023?",
            "isRequired": true
          }
        ],
        "title": "C. Key Skills"
      },
      {
        "name": "page4",
        "elements": [
          {
            "type": "matrixdropdown",
            "name": "Key Success Behaviours",
            "visible": false,
            "title": "Project Management Competency",
            "description": "Please RATE yourself on the following important success behavior.  For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "isRequired": true,
            "columns": [
              {
                "name": "Self Rating",
                "title": "Self Rating",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "rating",
            "rows": [
              "Project Conceptualization - Conceptualizes the project, its design elements, its fundamental framework, its mediums of experience, its fitment to Business Line and areas that may need secondary service/support from other Business Lines",
              "Project Planning & Handover - Creating a project charter and handover process",
              "Financial Management - Understanding project costs, taking action to optimize costs, balancing cost and quality",
              "People Management - Managing people, assigning correct roles, ensuring productivity, driving discipline, managing day to day people related issues"
            ]
          },
          {
            "type": "matrixdropdown",
            "name": "Personnel Competency",
            "title": "Personnel Competency",
            "description": "Please RATE yourself on the following important success behavior.  For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "isRequired": true,
            "columns": [
              {
                "name": "Self Rating",
                "title": "Self Rating",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "rating",
            "rows": [
              "Industry and Market Knowledge - Aware of current and future industry/market trends, understand client behaviours and needs, knows what competition offers and role of Client's company overall",
              "Innovation mindset - asks critical questions, challenges status quo, brings new ideas and perspective to all aspects of work",
              "Data and Insights driven Decision Making - Looks at qualitative and quantitative insights that impact success of project planning and execution",
              "Customer Focus - Keeps customer in the centre of all discussions/alignments/decisions, seeks feedback and makes customer services a priority",
              "Achievement orientated - Has fire in the belly, wants to succeed and makes deliberate effort to do well and outdo oneself",
              "Capability Building - Developed and strengthened the skills, instincts, abilities, processes and resources that organizations need to survive, adapt, and thrive in a fast-changing world.",
              "Communication excellence - Able to convey information to people clearly and simply, in a way that means things are understood and get done.",
              "Negotiation skills - Able to professionally reach an agreement that best meets both sides requirement, builds solid foundation on which to build future relations"
            ]
          }
        ],
        "title": "D. Key Success Behaviours"
      }
    ]
  }
}


export default bdFunctionHead;