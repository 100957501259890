import projectManager from './surveyModels/projectManager'
import supportFunctionHead from './surveyModels/supportFunctionHead'
import bdManager from './surveyModels/bdManager'
import bdFunctionHead from './surveyModels/bdFunctionHead'
import individualContributor from './surveyModels/individualContributor'
import businessLineHead from './surveyModels/businessLineHead'
import completedProjects from './surveyModels/completedProjects'
import test from './surveyModels/test'

const useAPI = () => {
  return [
    test,
    individualContributor,
    bdManager,
    supportFunctionHead,
    projectManager,
    bdFunctionHead,
    businessLineHead,
    completedProjects,
  ]
}
export default useAPI