import mainInfo from './common/mainInfo.js'
import keyGoals from './common/keyGoals.js'
import keySkills from './common/keySkills.js'


const test = {
  "id": "test",
  "config": {
    "title": "Individual Contributor Appraisal Form",
    "description": "All Functions And Teams",
    "logoPosition": "right",
    "showQuestionNumbers": "off",
    'showPreviewBeforeComplete': "showAnsweredQuestions",
    "pages": [
      mainInfo
    ]
  }
}


export default test;