const completedProjects = {
  "id": "completed-projects",
  config: {
    "title": "2022 Completed Projects Review Template ",
    "logoPosition": "right",
    "pages": [
      {
        "name": "MainInfo",
        "elements": [
          {
            "type": "text",
            "name": "Project Name",
            "title": "Project Name",
            "isRequired": true
          },
          {
            "type": "text",
            "name": "Project Client",
            "title": "Project Client",
            "isRequired": true
          },
          {
            "type": "text",
            "name": "Financial Value of the Project",
            "title": "Financial Value of the Project",
            "isRequired": true
          },
          {
            "type": "text",
            "name": "Project Business Line",
            "title": "Project Business Line",
            "isRequired": true
          },
          {
            "type": "text",
            "name": "Project Start Date",
            "title": "Project Start Date",
            "isRequired": true,
            "inputType": "date"
          },
          {
            "type": "text",
            "name": "Project End Date",
            "title": "Project End Date",
            "isRequired": true,
            "inputType": "date"
          },
          {
            "type": "text",
            "name": "Delay days",
            "title": "Delay days",
            "isRequired": true,
            "inputType": "number"
          },
          {
            "type": "text",
            "name": "Reasons for delay",
            "title": "Reasons for delay",
            "isRequired": true
          },
          {
            "type": "matrixdynamic",
            "name": "Core Project Team & Roles",
            "title": "Core Project Team & Roles",
            "columns": [
              {
                "name": "Leader/PMO",
                "title": "Leader/PMO",
                "isRequired": true
              },
              {
                "name": "Roles of people",
                "title": "Names of people",
                "isRequired": true

              },
              {
                "name": "Roles of people",
                "title": "Roles of people",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "text"
          },
          {
            "type": "text",
            "name": "Value of work outsourced/sub contracted in the project",
            "title": "Value of work outsourced/sub contracted in the project",
            "inputType": "number",
            "autocomplete": "transaction-currency",
            "isRequired": true
          },
          {
            "type": "radiogroup",
            "name": "How complex was this project",
            "title": "How complex was this project",
            "isRequired": true,
            "choices": [
              {
                "value": "Very complex",
                "text": "Very complex due to novelty, scale, interdependencies (internal or external), range and variety of deliverables"
              },
              {
                "value": "Moderate complex",
                "text": "Moderate complex - Familiar project but complex because of its scale , use of new tools/tech , complex client and/or lack of internal capacity or due to internal challenges of quality and resourcing \t\t"
              },
              {
                "value": "Easy",
                "text": "Easy - routine/familiar project with no major internal challenges of resourcing needs. The project had more scale than usual that stretched our capacity"
              },
              {
                "value": "Very Easy ",
                "text": "Very Easy - routine , familiar , no difference in scale and variables."
              }
            ]
          }
        ],
        "title": "A. Project Dynamics"
      },
      {
        "name": "BusinessLineProjectsOverview",
        "elements": [
          {
            "type": "panel",
            "name": "Project Conceptualization",
            "elements": [
              {
                "type": "radiogroup",
                "name": "Degree of innovation displayed in project design",
                "title": "Degree of innovation displayed in project design",
                "isRequired": true,
                "choices": [
                  "Very High - Novel concept , as compared to industry",
                  "High - Novel to TalentS. Other have done this but we were doing for 1st time",
                  "Medium - Adaptation of what we have done in the past",
                  "Low -Routine project"
                ]
              },
              {
                "type": "radiogroup",
                "name": "Very High - Client based their scope on our concept",
                "title": "Very High - Client based their scope on our concept",
                "isRequired": true,
                "choices": [
                  "Very High - Client based their scope on our concept",
                  "High - Solution was 100% tailored to client  need",
                  "Medium - Client need was addressed , but not fully , as a standard solution was applied after minor adjustments",
                  "Low - Client need was not met up to our satisfaction "
                ]
              },
              {
                "type": "radiogroup",
                "name": "None . Scope that was signed off , stayed till the end",
                "title": "None . Scope that was signed off , stayed till the end ",
                "isRequired": true,
                "choices": [
                  "Very High - Client based their scope on our concept",
                  "High - Solution was 100% tailored to client  need",
                  "Medium - Client need was addressed , but not fully , as a standard solution was applied after minor adjustments",
                  "Low - Client need was not met up to our satisfaction "
                ]
              }
            ],
            "title": "1. Project Conceptualization"
          },
          {
            "type": "panel",
            "name": "2. Financials",
            "elements": [
              {
                "type": "radiogroup",
                "name": "If the project had savings , what was done with the saving",
                "title": "If the project had savings , what was done with the saving",
                "isRequired": true,
                "choices": [
                  "Saving was used to offer the client more value add and differentiated outcomes",
                  "Saving was used to build internal skill capacity and capability or enhance our skill assets",
                  "Saving was used to hire more people",
                  "Saving was unused/there was no saving "
                ]
              },
              {
                "type": "radiogroup",
                "name": "Variance in planned budget or cost",
                "title": "Variance in planned budget or cost",
                "isRequired": true,
                "choices": [
                  "No variance",
                  "Low variance (0-5%)",
                  "Medium variance (5-10%)",
                  "High variance (10-15%)"
                ]
              },
              {
                "type": "radiogroup",
                "name": "Variance in planned revenue",
                "title": "Variance in planned revenue",
                "isRequired": true,
                "choices": [
                  "No variance",
                  "Low variance (0-5%)",
                  "Medium variance (5-10%)",
                  "High variance (>10%)"
                ]
              },
              {
                "type": "radiogroup",
                "name": "Collections",
                "title": "Collections",
                "isRequired": true,
                "choices": [
                  "Timely",
                  "Delay by 15-30 days",
                  "Delay by >30-45 days",
                  "Delay by 45-60 days"
                ]
              }
            ],
            "title": "2. Financials"
          },
          {
            "type": "panel",
            "name": "3. Efficiency",
            "elements": [
              {
                "type": "radiogroup",
                "name": "Manpower Utilization",
                "title": "Manpower Utilization",
                "isRequired": true,
                "choices": [
                  "Optimal",
                  "Marginally over or under manned",
                  "Project was unskilled  ( quality was low and last minute people had to be roped in ) or Staff in the project was not equitably utilized ( a few people delivered large part of the project)",
                  "Project manpower requirement were under estimated - had to be changed mid way "
                ]
              },
              {
                "type": "radiogroup",
                "name": "Clarity on scope, roles and expectations across the project management cycle",
                "title": "Clarity on scope, roles and expectations across the project management cycle",
                "isRequired": true,

                "choices": [
                  "Everyone , including client was clear on what was expected of the project and of them",
                  "Scope/ role confusions were ironed out in the initial stages and then did not need change",
                  "Scope /role confusions continues through a large period of time and needed constant re-evaluation",
                  "The project was unsatisfactory to both TalentS and the client due to mismatch of expectations "
                ]
              },
              {
                "type": "radiogroup",
                "name": "Deployment of Tools and Technology",
                "title": "Deployment of Tools and Technology",
                "isRequired": true,

                "choices": [
                  "High - tech/tools used reduced time and effort",
                  "Mid - tech/tools used reduced time but not efficiency",
                  "Low - tech/tools were used but their impact on time/efficiency remains unclear",
                  "Very low - Tech/tools were not deployed or hampered time and efficiency "
                ]
              }
            ],
            "title": "3. Efficiency"
          },
          {
            "type": "panel",
            "name": "panel1",
            "elements": [
              {
                "type": "radiogroup",
                "name": "Quantum of escalation of issues from client",
                "title": "Quantum of escalation of issues from client",
                "isRequired": true,

                "choices": [
                  "0% reported",
                  "Medium - on critical lapses were escalated",
                  "Low - operational issues seemed to remain unresolved and were escalated",
                  "Very Low - Project escalation were high , PMO was changed"
                ]
              },
              {
                "type": "radiogroup",
                "name": "Resolution to customer queries",
                "title": "Resolution to customer queries",
                "isRequired": true,

                "choices": [
                  "As per TAT/Agreement",
                  "Mostly as per TAT , there were delays on unexpected/complex issues",
                  "Most TATs were renegotiated or not met",
                  "There were no TATs/SLAs "
                ]
              }
            ],
            "title": "4. Customer Focus"
          }
        ],
        "visible": false,
        "title": "B. Project Review Area"
      },
      {
        "name": "Key Learnings of the Project",
        "elements": [
          {
            "type": "comment",
            "name": "What 1 to 3 things/areas the team could have done differently for this project?",
            "title": "What 1 to 3 things/areas the team could have done differently for this project?",
            "isRequired": true,
            "placeholder": "Use this space to include your response\t\t\t\t"
          },
          {
            "type": "comment",
            "name": "What should we CONTINUE to do , basis this project , in all projects",
            "title": "What should we CONTINUE to do , basis this project , in all projects",
            "isRequired": true,
            "placeholder": "Use this space to include your response\t\t\t\t"
          },
          {
            "type": "comment",
            "name": "What should we START to do , basis this project , in all projects",
            "title": "What should we START to do , basis this project , in all projects",
            "isRequired": true,
            "placeholder": "Use this space to include your response\t\t\t\t"
          },
          {
            "type": "comment",
            "name": "What we should STOP doing , basis this project , in all projects",
            "title": "What we should STOP doing , basis this project , in all projects",
            "isRequired": true,
            "placeholder": "Use this space to include your response\t\t\t\t"
          },
          {
            "type": "comment",
            "name": "Name any 1-2 star/key contributors of the project that drive the rating upwards and 1-2 that drove the roject rating downwards",
            "title": "Name any 1-2 star/key contributors of the project that drive the rating upwards and 1-2 that drove the roject rating downwards",
            "isRequired": true,
            "placeholder": "Use this space to include your response\t\t\t\t"
          }
        ],
        "title": "C. Key Learnings of the Project- to be filled by PMO in discussion with business line head)"
      },
      {
        "name": "Rating on the project assigned by important stakeholders",
        "elements": [
          {
            "type": "rating",
            "name": "PMO rating",
            "isRequired": true,
            "title": "PMO rating",
            "description": "5 - Excelled\n4 - Exceeded expectations \n3 - Met expectation\n2 - Can be improved to meet expectations\n1 - Below my expectations"
          },
          {
            "type": "rating",
            "name": "Business Line Head rating",
            "isRequired": true,
            "title": "Business Line Head rating",
            "description": "5 - Excelled\n4 - Exceeded expectations \n3 - Met expectation\n2 - Can be improved to meet expectations\n1 - Below my expectations"
          },
          {
            "type": "rating",
            "name": "GM Operations rating",
            "title": "GM Operations rating",
            "isRequired": true,
            "description": "5 - Excelled\n4 - Exceeded expectations \n3 - Met expectation\n2 - Can be improved to meet expectations\n1 - Below my expectations"
          },
          {
            "type": "rating",
            "name": "Business Development rating",
            "title": "Business Development rating",
            "isRequired": true,
            "description": "5 - Excelled\n4 - Exceeded expectations \n3 - Met expectation\n2 - Can be improved to meet expectations\n1 - Below my expectations"
          },
          {
            "type": "rating",
            "name": "COO rating",
            "title": "COO rating",
            "isRequired": true,
            "description": "5 - Excelled\n4 - Exceeded expectations \n3 - Met expectation\n2 - Can be improved to meet expectations\n1 - Below my expectations"
          }
        ],
        "title": "D. Rating on the project assigned by important stakeholders (use rating scale below)(When rating the project , the complexity of the project as stated in point A, must be considered )"
      }
    ]
  }
}


export default completedProjects;