import DropDownSelect from "../common/DropDownSelect";
import React, { FunctionComponent, PropsWithChildren } from "react";

const ActionPanel: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Select survey type
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Once selected, you will see rendered results for this form
            submission.
          </p>
        </div>
        <div className="mt-5">{children}</div>
      </div>
    </div>
  );
};

export default ActionPanel;
