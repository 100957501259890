import { useEffect, useState, useCallback, useRef } from "react";
import surveysService from "../../services/surveys.service";
import { Link } from "react-router-dom";
import { Survey } from "survey-react-ui";
import { StylesManager, Model } from "survey-core";

function sortByDate(data: any) {
  data.sort((a: any, b: any) => {
    const dateA: any = new Date(a.date);
    const dateB: any = new Date(b.date);
    return dateB - dateA;
  });
  return data;
}

async function saveSurveyResults(data: any) {
  const API_URL =
    process.env.REACT_APP_BACKEND_ENDPOINT ??
    "https://saeed-backend-wt6lzqy3ra-ew.a.run.app";
  let resp = await fetch(`${API_URL}/forms/`, {
    method: "POST",
    headers: {
      // "user-agent": "vscode-restclient",
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  });
  let res = await resp.json();
  return res;
}

const alertResults = async (sender: any, meta: any) => {
  const results = JSON.stringify(sender.data);
  let resp = await saveSurveyResults({
    ...meta,
    form_data: sender.data,
  });
  console.log(resp);
  let savedToken = localStorage.getItem("userToken");
  localStorage.clear();
  if (savedToken) {
    localStorage.setItem("userToken", savedToken);
  }
};

const SurveyItem: React.FC<{ id: string }> = ({ id }) => {
  var myCss = {
    question: {
      content:
        "sd-question__content question_content_custom sd-input--disabled::placeholder",
      titleOnAnswer: "question-title-answered",
    },
  };

  // const [submissions, setSubmission] = useState<any | null>(null);
  const [survey, setSurveyRender] = useState<any | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [surveyID, setSurveyID] = useState<any | null>(null);
  const [managerID, setManagerID] = useState<any | null>(null);
  const stateRef = useRef();
  const storageItemKey = "my-survey";
  const config = "";
  const handleClearDataClick = () => {
    localStorage.removeItem("surveyData");
    survey.data = {};
  };

  const handleSaveDataClick = () => {
    let savedDate: any = localStorage.getItem("savedSurveyData");
    if (!savedDate) {
      let savedSurveyData = [
        {
          id: survey.id,
          date: new Date().toISOString(),
          data: survey.data,
        },
      ];
      localStorage.setItem("savedSurveyData", JSON.stringify(savedSurveyData));
    }
    let currentSurveyData = JSON.parse(savedDate);
    currentSurveyData.push({
      id: survey.id,
      date: new Date().toISOString(),
      data: survey.data,
    });
    localStorage.setItem("savedSurveyData", JSON.stringify(currentSurveyData));
  };

  const handleLoadDataClick = () => {
    let savedData = localStorage.getItem("savedSurveyData");
    if (!savedData) {
      return;
    }
    let sortedData = sortByDate(JSON.parse(savedData));
    // console.log(savedData)
    survey.data = sortedData[0].data;
    // localStorage.setItem("surveyData", JSON.stringify(survey.data))
  };
  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("userToken");
        const { submission, user_form_config, manager_form_config } =
          await surveysService.getSingleSurvey(id, token);
        const surveyRender = new Model(manager_form_config);

        surveyRender.data = submission?.form_data;
        surveyRender.sendResultOnPageNext = true;
        // Save survey results
        // survey.onComplete.add((survey: any) => {
        //   saveSurveyData(survey);
        // });
        // stateRef.current = submissions;
        surveyRender.onComplete.add((survey: any) =>
          alertResults(survey, {
            email: submission.email,
            form_id: `${submission.form_id}-manager-review`,
            manager_id: submission.manager_id,
          })
        );
        // Restore survey results
        // surveyRender.onPartialSend.add((survey: any) => {
        //   saveSurveyData(survey);
        // });

        // const prevData = window.localStorage.getItem(storageItemKey) || null;
        // if (prevData) {
        //   const data = JSON.parse(prevData);
        //   surveyRender.data = data;
        //   if (data.pageNo) {
        //     surveyRender.currentPageNo = data.pageNo;
        //   }
        // }

        // config = manager_form_config;
        setSurveyRender(surveyRender);
        setInitialized(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  function saveSurveyData(survey: any) {
    const data = survey.data;
    data.pageNo = survey.currentPageNo;
    window.localStorage.setItem(storageItemKey, JSON.stringify(data));
  }

  // useEffect(() => {
  //   if (submissions && survey) {
  //     // console.log(submissions, "submissions");
  //   }
  // }, [survey]);

  // const alertResults = useCallback(
  //   (sender: any) => {
  //     const results = JSON.stringify(sender.data);
  //     let resp = saveSurveyResults({
  //       email: email,
  //       form_id: surveyID,
  //       manager_id: managerID,
  //       form_data: sender.data,
  //     });
  //     console.log(resp);
  //     localStorage.clear();
  //   },
  //   [email, surveyID, managerID]
  // );
  if (!initialized) {
    return null;
  }
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="flex justify-between">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Applicant Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        <Link
          to={`/surveys`}
          className="inline-flex items-center rounded-full border border-transparent text-indigo-600 p-2.5 px-10 shadow-sm hover:tex-indigo-700 focus:outline-none hover:underline"
        >
          Back To Surveys
        </Link>
      </div>
      <div className="flex space-between">
        <span className="isolate inline-flex rounded-md shadow-sm">
          {/* <button
            type="button"
            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            onClick={handleClearDataClick}
          >
            Clear Data
          </button> */}
          <button
            type="button"
            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            onClick={handleSaveDataClick}
          >
            Save Data
          </button>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            onClick={handleLoadDataClick}
          >
            Load Data
          </button>
        </span>
      </div>
      <div>{survey && <Survey model={survey} css={myCss} />}</div>
    </div>
  );
};

export default SurveyItem;
