import { createRef } from "react";
import { useState } from "react";

export function AuthenticatedLink({ url, filename, action, children }: any) {
  // const link: any = createRef();
  // const url = window.URL.createObjectURL(file);
  const [loading, setLoading] = useState(false);

  const handleAction = async () => {
    setLoading(true);
    const token = localStorage.getItem("userToken");
    const link = document.createElement("a");

    if (link.href) {
      return;
    }

    const result = await fetch(url, {
      headers: { authorization: `Bearer ${token}` },
    });

    if (action === "download") {
      const blob = await result.blob();
      const href = window.URL.createObjectURL(blob);
      link.setAttribute("download", filename ?? `file.csv`); // or any other extension
      link.href = href;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } else {
      link.click();
      setLoading(false);
    }
  };

  return (
    <>
      <a role="button" onClick={handleAction}>
        <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
          {!loading ? children : "loading..."}
        </button>
      </a>
    </>
  );
}
