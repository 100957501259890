const authEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;

const authService = {
  createUser: async (payload: any) => {
    const response = await fetch(`${authEndpoint}auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (response.status !== 200) {
      console.error(data);
      throw new Error(data);
    }
    return data;
  },
  login: async (username: string, password: string) => {
    var details = {
      username: username,
      password: password,
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      //@ts-ignore
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    //@ts-ignore
    formBody = formBody.join("&");

    const response = await fetch(`${authEndpoint}/auth/jwt/login`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/x-www-form-urlencoded",
      },
      //@ts-ignore
      body: formBody,
    });
    const data = await response.json();
    if (response.status !== 200) {
      console.error(data?.detail);
      throw new Error(data?.detail);
    }
    return data.access_token;
  },
};

export default authService;
