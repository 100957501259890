
const mainInfo = {
  "name": "Main Info",
  "elements": [
    {
      "type": "text",
      "name": "Employee ID",
      "title": "Your employee ID",
      "isRequired": true
    },
    {
      "type": "text",
      "name": "Name",
      "title": "Name",
      "isRequired": true
    },
    {
      "type": "text",
      "name": "Designation",
      "title": "Designation",
      "isRequired": true
    },
    {
      "type": "dropdown",
      "name": "Department",
      "title": "Department",
      "isRequired": true,
      "choices": [
        "Business Development",
        "PM Office",
        "ADMIN",
        "Design and Engineering",
        "Innovation Spaces - Digital Labs",
        "Events",
        "Museums",
        "Creative Learning",
        "Maintenance & AV systems Department",
        "Admin Services & Event Management Department",
        "Shared Services - IT",
        "Human Resource",
        "Finance",
        "Support Services",
        "Shared  Services - Supply Chain",
        "Innovation Spaces - Enrichment programs",
        "Innovation Spaces - Tech.",
        "Finance Department",
        "CEO Office"
      ]
    },
    {
      "type": "text",
      "name": "Manager ID",
      "title": "Manager ID",
      "isRequired": true
    }
  ]
}

export default mainInfo