import React from "react";
import { useState, useEffect } from "react";
import "survey-analytics/survey.analytics.css";
import { Model } from "survey-core";
import { MatrixPlotly, SelectBasePlotly } from "survey-analytics";
import { VisualizationPanel } from "survey-analytics";
import { json } from "./json";
import { useQuery } from "react-query";
import DropDownSelect from "../../components/common/DropDownSelect";
import ActionPanel from "../../components/ActionPanel";
import { AuthenticatedLink } from "../../components/common/AuthenticatedDownloadLink";

async function getSurveyResults(filter = "") {
  const API_URL =
    process.env.REACT_APP_BACKEND_ENDPOINT ??
    "https://saeed-backend-wt6lzqy3ra-ew.a.run.app";
  const token = localStorage.getItem("userToken");
  let resp = await fetch(`${API_URL}/surveys/?form_id=${filter}`, {
    method: "GET",
    headers: {
      // "user-agent": "vscode-restclient",
      "content-type": "application/json",
      authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(data),
  });
  let res = await resp.json();
  return res;
}

async function getSurveyTypes() {
  const API_URL =
    process.env.REACT_APP_BACKEND_ENDPOINT ??
    "https://saeed-backend-wt6lzqy3ra-ew.a.run.app";
  const token = localStorage.getItem("userToken");
  let resp = await fetch(`${API_URL}/surveys/types`, {
    method: "GET",
    headers: {
      // "user-agent": "vscode-restclient",
      "content-type": "application/json",
      authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(data),
  });
  let res = await resp.json();
  return res;
}

const DashboardPage = () => {
  MatrixPlotly.types = ["stackedbar", "bar", "pie", "doughnut"];
  SelectBasePlotly.types = ["doughnut", "bar", "pie", "scatter"];
  const [surveyData, setData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [options, setOptions] = useState(null);

  const handleOnSelect = (e: any) => {
    setFilter(e);
    setLoading(true);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const types = await getSurveyTypes();
        const opts = types.map((type: any) => type._id);
        setOptions(opts);
        console.log(opts);
        const { surveys } = await getSurveyResults();
        console.log(surveys);
        setData(surveys);
        // if (surveys) {
        //   setLoading(false)
        // }
      } catch (err) {
        console.log(err);
      }
    })();

    // var options = {
    //   //Add this line
    // };
  }, []);

  useEffect(() => {
    (async () => {
      const { surveys } = await getSurveyResults(filter);
      console.log(surveys, "FILTERED");
      setData(surveys);
      setLoading(false);
    })();
  }, [filter]);

  useEffect(() => {
    if (!surveyData) {
      return;
    }
    const survey = new Model(json);
    const allQuestions = survey.getAllQuestions();
    let surveys = surveyData;
    console.log(surveys, "surveys");
    let surveysData = surveys?.map((survey: any) => survey.form_data);
    const visPanel = new VisualizationPanel(allQuestions, surveysData, {
      labelTruncateLength: 27,
      haveCommercialLicense: true,
    });
    visPanel.showHeader = true;
    visPanel.refresh();
    visPanel.render("surveyDashboardContainer");
    setLoading(false);
  }, [surveyData, filteredData]);

  return (
    <>
      <ActionPanel>
        {options && (
          <DropDownSelect options={options} onSelect={handleOnSelect} />
        )}
        {filter && (
          <div className="my-2">
            <AuthenticatedLink
              url={`${process.env.REACT_APP_BACKEND_ENDPOINT}/surveys/file?form_id=${filter}`}
              filename={`${filter}.csv`}
              action="download"
            >
              Download CSV {`${filter}.csv`}
            </AuthenticatedLink>

            <AuthenticatedLink
              url={`${process.env.REACT_APP_BACKEND_ENDPOINT}/surveys/export-to-airtable?form_id=${filter}`}
              filename=""
              action="click"
            >
              Export {`${filter}.csv`} results to Airtable
            </AuthenticatedLink>

            {/* <a
              className="btn btn-primary"
              href={`${process.env.REACT_APP_BACKEND_ENDPOINT}/surveys/file?form_id=${filter}`}
            >
              <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                Download CSV
              </button>
            </a> */}
          </div>
        )}
      </ActionPanel>
      {loading && <div>Loading...</div>}
      <div
        className={loading ? "hidden" : ""}
        key={filter}
        id="surveyDashboardContainer"
      ></div>
    </>
  );
};

export default DashboardPage;
