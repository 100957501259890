import { Form, useLoaderData } from "react-router-dom";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import useApi from "../../services/api.service";
import "survey-core/defaultV2.css";
// import "../index.css";
StylesManager.applyTheme("defaultV2");

export function getSurveyByID(assessmentId: string) {
  const surveys = useApi();
  let res = surveys.find((survey) => survey.id === assessmentId);
  return res;
}

async function saveSurveyResults(
  email: string,
  formId: string,
  managerID: string,
  data: any
) {
  const API_URL =
    process.env.REACT_APP_BACKEND_ENDPOINT ??
    "https://saeed-backend-wt6lzqy3ra-ew.a.run.app";
  let resp = await fetch(`${API_URL}/forms/`, {
    method: "POST",
    headers: {
      // "user-agent": "vscode-restclient",
      "content-type": "application/json",
    },
    body: JSON.stringify({
      email: email || "noEmail",
      form_id: formId || "noFormId",
      manager_id: managerID || "noManagerId",
      form_data: data,
    }),
  });
  let res = await resp.json();
  return res;
}

// const ClearLocalStorageButton = () => {
//   const handleClick = () => {
//     localStorage.removeItem('surveyData');
//   };
//   return (
//     <button onClick={handleClick}>
//       Clear Local Storage
//     </button>
//   );
// }

function sortByDate(data: any) {
  data.sort((a: any, b: any) => {
    const dateA: any = new Date(a.date);
    const dateB: any = new Date(b.date);
    return dateB - dateA;
  });
  return data;
}

export default function Assessment() {
  const handleClearDataClick = () => {
    localStorage.removeItem("surveyData");
    survey.data = {};
  };

  const handleSaveDataClick = () => {
    let savedDate: any = localStorage.getItem("savedSurveyData");
    if (!savedDate) {
      let savedSurveyData = [
        {
          id: survey.id,
          date: new Date().toISOString(),
          data: survey.data,
        },
      ];
      localStorage.setItem("savedSurveyData", JSON.stringify(savedSurveyData));
    }
    let currentSurveyData = JSON.parse(savedDate);
    currentSurveyData.push({
      id: survey.id,
      date: new Date().toISOString(),
      data: survey.data,
    });
    localStorage.setItem("savedSurveyData", JSON.stringify(currentSurveyData));
  };

  const handleLoadDataClick = () => {
    let savedData = localStorage.getItem("savedSurveyData");
    if (!savedData) {
      return;
    }
    let sortedData = sortByDate(JSON.parse(savedData));
    // console.log(savedData)
    survey.data = sortedData[0].data;
    // localStorage.setItem("surveyData", JSON.stringify(survey.data))
  };

  StylesManager.applyTheme("defaultV2");
  const { assessmentId } = useParams();
  let mySurvey: any;
  if (assessmentId) {
    mySurvey = getSurveyByID(assessmentId);
  } else {
    useNavigate()("/dashboard");
  }

  const [searchParams] = useSearchParams();

  // const searchParams = new URLSearchParams(document.location.search)
  const userEmail = searchParams.get("email");
  const managerID = searchParams.get("line_manager_id");
  const userId = searchParams.get("employee_id");
  if (userId) {
    mySurvey.config.pages[0].elements[0].defaultValueExpression = userId;
  }
  const survey = new Model(mySurvey.config);
  console.log(localStorage.getItem("surveyData"));

  const alertResults = useCallback((sender: any) => {
    const results = JSON.stringify(sender.data);
    let resp = saveSurveyResults(
      userEmail || "noEmail",
      assessmentId || "noFormId",
      managerID || "noManagerId",
      sender.data
    );
    console.log(resp);
    localStorage.clear();
  }, []);

  survey.onComplete.add(alertResults);

  survey.onValueChanged.add((survey, { name, question, value }) => {
    console.log(`The ${name} question value has changed to ${value}.`);
    console.log(survey.data);
    localStorage.setItem("surveyData", JSON.stringify(survey.data));
  });

  useEffect(() => {
    let surveyData = localStorage.getItem("surveyData");
    if (surveyData) {
      survey.data = JSON.parse(surveyData);
    }
  }, []);

  return (
    <div className="w-[36rem]">
      <div className="flex space-between w-1/2">
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            onClick={handleClearDataClick}
          >
            Clear Data
          </button>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            onClick={handleSaveDataClick}
          >
            Save Data
          </button>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            onClick={handleLoadDataClick}
          >
            Load Data
          </button>
        </span>
      </div>
      <Survey model={survey} />;
    </div>
  );
}
