import React from 'react';
import { RotatingLines} from 'react-loader-spinner';

type LoaderProps = {
  size?: string;
  color?: string;
};

const Loader: React.FC<LoaderProps> = ({ size, color }) => {
  return (
    <RotatingLines
      animationDuration="0.75"
      strokeColor={color || 'grey'}
      // strokeWidth={size || '5'}
      visible={true}
      width={size || '25'}
    />
  );
};

export default Loader;
