import { Outlet, Link, useLoaderData } from "react-router-dom";
import { surveyService } from "../services/survey.service";

export async function loader() {
  // const surveys = await surveyService.fetchAll();
  // return { surveys };
}

export default function Root() {
  // const { surveys } = surveyService.fetchAll();
  return (
    <>

      <div id="detail">
        <Outlet />
      </div>
    </>
  );
}
