import { useEffect, useState } from "react";
import surveysService from "../../services/surveys.service";
import { Link, useParams } from "react-router-dom";
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
} from "@heroicons/react/20/solid";
import Loader from "../../common/components/Loader";

const SurveyList = () => {
  const [surveys, setSurveys] = useState<any[] | null>(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [pagesCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const getSurveys = async (skip: number, filter?: string | null) => {
    try {
      // setLoading(true);
      const { surveys, count } = await surveysService.getSurveys(
        skip,
        token,
        filter
      );
      setSurveys(surveys);
      setTotalCount(count);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     const { surveys, count } = await surveysService.getSurveys(
  //       page * pageSize,
  //       filter
  //     );
  //     // await getSurveys(page * pageSize, filter);
  //     setSurveys(surveys);
  //     setTotalCount(count);
  //     setLoading(false);
  //   })();
  // }, [page]);

  useEffect(() => {
    (async () => {
      const { surveys, count } = await surveysService.getSurveys(
        page * pageSize,
        token,
        filter
      );
      setSurveys(surveys);
      setTotalCount(count);
      setLoading(false);
    })();
  }, [token, page]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      setToken(localStorage.getItem("userToken"));
    })();
  }, []);

  return surveys && loading ? (
    <>loading...</>
  ) : (
    <div className="">
      <div className="sm:flex sm:items-center my-6">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            surveys: {totalCount}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the submissions that if relevant to your account.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-2">
          <div>
            <label htmlFor="filter" className="sr-only">
              Filter
            </label>
            <input
              type="text"
              name="filter"
              id="filter"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Filter"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            // onClick={() => getSurveys(page * pageSize, filter)}
          >
            Find
          </button>
        </div>
      </div>
      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
        <div className="-mt-px flex w-0 flex-1">
          <button
            onClick={() => setPage((prevPage) => --prevPage)}
            disabled={page === 0}
            className={`inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500  ${
              page === 0
                ? "opacity-80"
                : "hover:border-gray-300 hover:text-gray-700"
            }`}
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
        </div>
        <div className="hidden md:-mt-px md:flex">
          <div className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
            <span> Page: {page + 1}</span>
          </div>
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            onClick={() => setPage((prevPage) => ++prevPage)}
            className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="table-auto divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Department
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Designation
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Form ID
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Full info</span>
                    </th>
                  </tr>
                </thead>
                {loading || (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {surveys?.map((survey) => (
                      <tr key={survey._id}>
                        <td className="w-1/6 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {survey.email ?? survey.form_id}
                        </td>
                        <td className="w-1/6 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {survey.name}
                        </td>
                        <td className="w-1/6 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {survey.department}
                        </td>
                        <td className="w-1/6 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {survey.designation}
                        </td>
                        <td className="w-1/6 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {survey.form_id}
                        </td>
                        <td className="w-1/6 relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Link
                            to={`${survey._id}`}
                            className="text-indigo-600 hover:text-indigo-900 hover:underline"
                          >
                            Full info
                            <span className="sr-only">, {survey.name}</span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {loading && (
                <div className="flex justify-center my-5 w-full">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyList;
