const supportFunctionHead = {
  "id": "support-function-head",
  config: {
    "title": "SUPPORT FUNCTION HEAD APPRAISAL FORM ( ACCOUNTS/ HR / SUPPLY CHAIN)",
    "logoPosition": "right",
    "showQuestionNumbers": "off",
    'showPreviewBeforeComplete': "showAnsweredQuestions",
    "pages": [
      {
        "name": "Main Info",
        "elements": [
          {
            "type": "text",
            "name": "Employee ID",
            "title": "Your employee ID",
            "isRequired": true
          },
          {
            "type": "text",
            "name": "Name",
            "title": "Name",
            "isRequired": true
          },
          {
            "type": "text",
            "name": "Designation",
            "title": "Designation",
            "isRequired": true
          },
          {
            "type": "dropdown",
            "name": "Department",
            "title": "Department",
            "isRequired": true,
            "choices": [
              "Business Development",
              "PM Office",
              "ADMIN",
              "Design and Engineering",
              "Innovation Spaces - Digital Labs",
              "Events",
              "Museums",
              "Creative Learning",
              "Maintenance & AV systems Department",
              "Admin Services & Event Management Department",
              "Shared Services - IT",
              "Human Resource",
              "Finance",
              "Support Services",
              "Shared  Services - Supply Chain",
              "Innovation Spaces - Enrichment programs",
              "Innovation Spaces - Tech.",
              "Finance Department",
              "CEO Office"
            ]
          },
          {
            "type": "text",
            "name": "Manager ID",
            "title": "Manager ID",
            "isRequired": true
          }
        ]
      },
      {
        "name": "KeyGoals",
        "elements": [
          {
            "type": "matrixdynamic",
            "allowAddRows": false,
            "allowRemoveRows": false,
            "rowCount": 5,
            "name": "Top 5 goals",
            "title": "Top 5 goals",
            "description": "Use this space to list the top 5 goals that your function/business has delivered in 2022?.  For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "isRequired": true,
            "columns": [
              {
                "name": "Goal",
                "title": "Goal",
                "cellType": "comment",
                "isRequired": true
              },
              {
                "name": "Self Rating",
                "title": "Self Rating",
                "cellType": "rating",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
          },
          {
            "type": "comment",
            "name": "How have these 5 goals impacted /enhanced the business?",
            "title": "How have these 5 goals impacted /enhanced the business?",
            "isRequired": true,
            "placeholder": "Use this space to explain how have these goals impacted/enhanced the business"
          }
        ],
        "title": "B. Key Goals"
      },
      {
        "name": "KeySkills",
        "elements": [
          {
            "type": "matrixdynamic",
            "allowAddRows": false,
            "allowRemoveRows": false,
            "rowCount": 3,
            "name": "Picked up skills",
            "title": "Picked up skills",
            "description": "What skills have you picked up in 2022 (These could be any skills, formal or informal, technical or non technical). Explain How did you pick this up (formal course/exposure/training by TalentS/observation and experience in the project etc.)",
            "isRequired": true,
            "columns": [
              {
                "name": "Skill",
                "title": "Skill",
                "cellType": "text",
                "isRequired": true
              },
              {
                "name": "How did you pick this up",
                "title": "How did you pick this up",
                "cellType": "text",
                "isRequired": true
              },
              {
                "name": "How will you use this skill in the future?",
                "title": "How will you use this skill in the future?",
                "cellType": "text",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
          },
          {
            "type": "comment",
            "name": "What areas of your business/function have you transformed via technology or innovation?",
            "title": "What areas of your business/function have you transformed via technology or innovation?",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What new capabilities or skills have you built in your function or business in 2022?",
            "title": "What new capabilities or skills have you built in your function or business in 2022?",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What contribution have you made to hiring/retention/engagement of your direct team",
            "title": "What contribution have you made to hiring/retention/engagement of your direct team",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What contribution have you made to cost optimization?",
            "title": "What contribution have you made to cost optimization?",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What strengths did you demonstrate across your work in 2022? List top 3-5 strengths.",
            "title": "What strengths did you demonstrate across your work in 2022? List top 3-5 strengths.",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What improvement areas do you identify for yourself for 2023 taking into consideration your contribution in 2022",
            "title": "What improvement areas do you identify for yourself for 2023 taking into consideration your contribution in 2022",
            "isRequired": true
          },
          {
            "type": "comment",
            "name": "What is your aspiration? What kind of a role and projects, do you want to do with TalentS in 2023?",
            "title": "What is your aspiration? What kind of a role and projects, do you want to do with TalentS in 2023?",
            "isRequired": true
          }
        ],
        "title": "C. Key Skills"
      },
      {
        "name": "page4",
        "elements": [

          {
            "type": "matrixdropdown",
            "name": "Personnel Competency",
            "title": "Personnel Competency",
            "description": "Please RATE yourself on the following important success behavior. For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
            "isRequired": true,
            "columns": [
              {
                "name": "Self Rating",
                "title": "Self Rating",
                "isRequired": true
              }
            ],
            "choices": [
              1,
              2,
              3,
              4,
              5
            ],
            "cellType": "rating",
            "rows": [
              "Industry and Market Knowledge - Aware of current and future industry/market trends, understand client behaviours and needs, knows what competition offers and role of Client's company overall",
              "Innovation mindset - asks critical questions, challenges status quo, brings new ideas and perspective to all aspects of work",
              "Data and Insights driven Decision Making - Looks at qualitative and quantitative insights that impact success of project planning and execution",
              "Customer Focus - Keeps customer in the centre of all discussions/alignments/decisions, seeks feedback and makes customer services a priority",
              "Achievement orientated - Has fire in the belly, wants to succeed and makes deliberate effort to do well and outdo oneself",
              "Communication excellence - Able to convey information to people clearly and simply, in a way that means things are understood and get done.",
              "Financial Acumen - Understands cost of doing business and thinks about commercial value across all decisions",
              "Negotiation skills - Able to professionally reach an agreement that best meets both sides requirement, builds solid foundation on which to build future relations"
            ]
          }
        ],
        "title": "D. Key Success Behaviours"
      }
    ]
  }
}

export default supportFunctionHead;