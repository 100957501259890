import React from "react";
import ReactDOM from "react-dom/client";
import "survey-core/survey.min.css";
import {
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Root, { loader as rootLoader } from "./routes/root";
import ErrorPage from "./error-page";
import Assessment from "./pages/AssessmentItem";
import SurveyItemPage from "./pages/SurveyItemPage";
import SignInPage from "./pages/SignInPage";
import SurveysList from "./pages/SurveyListPage";
import { Navbar } from "./components/Nav";
import { LegacyNavbar } from "./components/Nav/LegacyNav";
import Layout from "./components/Layout";
import DashboardPage from "./pages/Dashboard";
import AssessmentList from "./pages/AssessmentList";
import HomePage from "./pages/HomePage";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/assessment",
    element: <AssessmentList />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/assessment",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ":assessmentId",
        element: <Assessment />,
      },
    ],
  },
  {
    path: "/surveys",
    element: <SurveysList />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
    errorElement: <ErrorPage />,
  },
  // TODO: RESEARCH HOW TO PASS PARAMS TO ROUTE
  {
    path: "/surveys",
    element: <SurveyItemPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ":id",
        element: <SurveyItemPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <SignInPage />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <Navbar /> */}
      <Layout>
        <RouterProvider router={router} />
        {/* </div> */}
      </Layout>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
