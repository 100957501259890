import React from "react";

const AssessmentList = () => {
  return (
    <div className="">
      <div className="sm:flex sm:items-center my-6">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Assessments</h1>
          <p className="mt-2 text-sm text-gray-700">
            Please get a personal link to submit your assessment
          </p>
        </div>
      </div>
    </div>
  );
};

export default AssessmentList;
