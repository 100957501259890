
const keyGoals = {
  "name": "Key Goals",
  "elements": [
    {
      "type": "matrixdynamic",
      "allowAddRows": false,
      "allowRemoveRows": false,
      "rowCount": 5,
      "name": "Top 5 goals",
      "title": "Top 5 goals",
      "description": "Use this space to list the top 5 goals that your function/business has delivered in 2022. For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
      "isRequired": true,
      "columns": [
        {
          "name": "Goal",
          "title": "Goal",
          "cellType": "comment",
          "isRequired": true
        },
        {
          "name": "Self Rating",
          "title": "Self Rating",
          "cellType": "rating",
          "isRequired": true
        }
      ],
      "choices": [
        1,
        2,
        3,
        4,
        5
      ],
    },
    {
      "type": "comment",
      "name": "How have these 5 goals impacted /enhanced the business?",
      "title": "How have these 5 goals impacted /enhanced the business?",
      "isRequired": true,
      "placeholder": "Use this space to explain how have these goals impacted/enhanced the business"
    }
  ],
  "title": "B. Key Goals"
}

export default keyGoals