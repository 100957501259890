
const businessLineOverview = {
  "name": "Business Line Projects Overview",
  "elements": [
    {
      "type": "matrixdropdown",
      "name": "Business Line Projects Overview Part 1",
      "title": "Business Line Projects Overview Part 1",
      "description": "For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
      "columns": [
        {
          "name": "2022",
          "title": "in 2022",
          "isRequired": true
        },
        {
          "name": "2021",
          "title": "in 2021",
          "isRequired": true
        },
        {
          "name": "Self Rating",
          "title": "Self Rating",
          "cellType": "rating",
          "isRequired": true
        }
      ],
      "choices": [
        1,
        2,
        3,
        4,
        5
      ],
      "cellType": "text",
      "rows": [
        "Financial value of the projects contracted",
        "Number of projects contracted",
        "Average value of contracted projects",
        "Number of projects contracted with existing client",
        "Number of projects from new clients",]
    },
    {
      "type": "matrixdropdown",
      "name": "Business Line Projects Overview Part 2",
      "title": "Business Line Projects Overview Part 2",
      "description": "For self ratings, use the scale where (1) you didn’t meet the expectation, (2) you started meeting expectations, (3) you met the expectation and delivered what was expected, (4) Exceeded expectations and (5) you Excelled.",
      "columns": [
        {
          "name": "2022",
          "title": "in 2022",
          "isRequired": true
        },
        {
          "name": "2021",
          "title": "in 2021",
          "isRequired": true
        },
        {
          "name": "Reason",
          "title": "Reason",
          "isRequired": true
        },
        {
          "name": "Self Rating",
          "title": "Self Rating",
          "cellType": "rating",
          "isRequired": true
        }
      ],
      "choices": [
        1,
        2,
        3,
        4,
        5
      ],
      "cellType": "text",
      "rows": [
        "Number of lost projects",
        "Number of contracted projects that had major changes in scope",
        "Number of contracted projects that were cancelled",
      ]
    }
  ],
  "title": "A. Business Line Projects Overview"
}

export default businessLineOverview