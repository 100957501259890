import React from "react";
import { useParams } from "react-router-dom";
import SurveyItem from "./SurveyItem";

const SurveyItemPage: React.FC = () => {
  const { id } = useParams();

  return (
    <div className="w-[36rem]">
      <SurveyItem id={id as string} />
    </div>
  );
};

export default SurveyItemPage;
